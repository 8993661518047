import gql from "graphql-tag";
import { accountFragment } from "../fragments/account";

export default gql`
  query pendingAccountsQuery(
    $shopIds: [ID]
    $filters: AccountFilterInput
    $first: ConnectionLimitInt
    $offset: Int
    $sortBy: [AccountSortBy]
  ) {
    pendingAccounts(
      shopIds: $shopIds
      filters: $filters
      first: $first
      offset: $offset
      sortBy: $sortBy
    ) {
      nodes {
        ...Account
      }
      totalCount
      __typename
    }
  }
  ${accountFragment}
`;
