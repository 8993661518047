import React, { useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import { useSnackbar } from "notistack";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import makeDataTableColumnFilter from "../../shared/useDataTable-custom/makeDataTableColumnFilter";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import primaryShopIdQuery from "../../../../graphql/queries/getPrimaryShopId";
import {
  Box,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import makeAnOfferEntriesQuery from "../graphql/queries/makeAnOfferEntries";
import DateCell from "./TableCells/DateCell";
import AvatarCell from "../../shared/components/TableCells/AvatarCell";
import ProductNumberCell from "../../shared/components/TableCells/ProductNumberCell";
import SerialNumberCell from "../../shared/components/TableCells/SerialNumberCell";
import OfferStatusCell from "./TableCells/OfferStatusCell";

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

const REMOVE_OFFERS = gql`
  mutation removeMakeAnOfferEntriesMutation($input: RemoveOffersInput!) {
    removeOffers(input: $input) {
      clientMutationId
      makeOfferIds
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  button: {
    color: theme.palette.colors.coolGrey500,
    fontWeight: 600,
    borderRadius: 0,
  },
  buttonAsc: {
    borderTop: "2px solid",
  },
  buttonDesc: {
    borderBottom: "2px solid",
  },
}));

/**
 * @name MakeAnOfferTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function MakeAnOfferTable({ history }) {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [removeOffers, { error: removeOffersError }] = useMutation(
    REMOVE_OFFERS
  );

  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [lastFetchVariables, setLastFetchVariables] = useState({});

  const { t } = useTranslation("ns1");

  // Create and memoize the column data
  const columns = useMemo(() => [
    {
      id: "selection",
      disableSorting: true,
      cellProps: {
        // Disables the cell click if the row is clickable
        // This is important if you have a callback for onRowClick, as the checkbox cell
        // will also trigger the row click.
        // Alternatively you can control the onClick with the following option
        // onClick: (event) => event.stopPropagation(),
        isClickDisabled: true,

        // All other props will be applied to the table cell.
        padding: "none",
      },
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <Checkbox {...getToggleAllRowsSelectedProps()} />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Cell: ({ row }) => (
        <Checkbox
          {...row.getToggleRowSelectedProps()}
          title={`Toggle row selection for ${row.values.fullName}`}
        />
      ),
    },
    {
      Header: "Offer ID",
      accessor: "_id",
    },
    {
      Header: "Kunde",
      cellProps: {
        isClickDisabled: true,
      },
      accessor: "account",
      id: "accountName",
      Cell: ({ cell }) => <AvatarCell account={cell.value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: makeDataTableColumnFilter({
        options: [
          { label: t("admin.table.offerStatus.offer"), value: "offer" },
          {
            label: t("admin.table.offerStatus.offerDeclined"),
            value: "offerDeclined",
          },
          {
            label: t("admin.table.offerStatus.offerAccepted"),
            value: "offerAccepted",
          },
          {
            label: t("admin.table.offerStatus.counterOffer"),
            value: "counterOffer",
          },
          {
            label: t("admin.table.offerStatus.counterOfferAccepted"),
            value: "counterOfferAccepted",
          },
          {
            label: t("admin.table.offerStatus.counterOfferDeclined"),
            value: "counterOfferDeclined",
          },
        ],
      }),
      Cell: ({ cell }) => <OfferStatusCell value={cell.value} />,
    },
    {
      Header: "Datum",
      accessor: "createdAt",
      Cell: ({ row }) => <DateCell row={row} />,
    },
    {
      Header: "Anzahl",
      accessor: "quantity",
    },
    {
      Header: "Produkttitel",
      id: "catalogProductTitle",
      accessor: "catalogProduct.title",
    },
    {
      Header: "Produktnummer",
      id: "catalogProductProductNumber",
      accessor: "catalogProduct.productNumber",
      Cell: ({ cell, row }) => (
        <ProductNumberCell productNumber={cell.value} row={row} />
      ),
    },
    {
      Header: "Seriennummer",
      id: "catalogProductSerialNumber",
      disableSorting: true,
      accessor: "chosenVariant.serialNumber",
      Cell: ({ cell, row }) => (
        <SerialNumberCell serialNumber={cell.value} row={row} />
      ),
    },
    /*
    {
      Header: () => <Box textAlign="right">Originalpreis</Box>,
      Cell: ({ cell }) => <Box textAlign="right" style={{textDecoration: "line-through"}}>{cell.value} €</Box>,
      accessor: "currentPrice"
    },
    */
    {
      // Header: () => <Box textAlign="right">Aktueller Preis</Box>,
      Header: <Box textAlign="right">Aktueller Preis</Box>,
      Cell: ({ cell }) => <Box textAlign="right">{cell.value} €</Box>,
      accessor: "currentPrice",
    },
  ]);

  const onFetchData = useCallback(
    async ({
      globalFilter,
      filters,
      pageIndex,
      pageSize,
      sortBy,
      skipCache = false,
    }) => {
      setIsLoading(true);
      setLastFetchVariables({
        globalFilter,
        filters,
        pageIndex,
        pageSize,
        sortBy,
      });

      const { data: shopData } = await apolloClient.query({
        query: primaryShopIdQuery,
      });

      let filterObject = {};
      filters.forEach((filter) => {
        filterObject[filter.id] = filter.value;
      });

      // TODO: Add loading and error handling
      const { data } = await apolloClient.query({
        query: makeAnOfferEntriesQuery,
        variables: {
          shopIds: [shopData.primaryShopId],
          first: pageSize,
          offset: pageIndex * pageSize,
          filters: {
            searchField: globalFilter,
            ...filterObject,
          },
          sortBy,
        },
        fetchPolicy: skipCache ? "network-only" : "cache-first",
      });

      const totalCount =
        data && data.makeAnOfferEntries && data.makeAnOfferEntries.totalCount;
      const nodes =
        data && data.makeAnOfferEntries && data.makeAnOfferEntries.nodes;

      setTableData(nodes);
      setPageCount(Math.ceil(totalCount / pageSize));
      setIsLoading(false);
    },
    [apolloClient, setTableData, setPageCount, setLastFetchVariables]
  );

  const onRowSelect = useCallback(
    async ({ selectedRows: newSelectedRows }) => {
      setSelectedRows(newSelectedRows || []);
    },
    [setSelectedRows, tableData]
  );

  // Row click callback
  const onRowClick = useCallback(
    async ({ row }) => {
      history.push(`/make-an-offer/${row.values._id}`);
    },
    [history]
  );

  const dataTableProps = useDataTable({
    data: tableData,
    columns,
    onFetchData,
    pageCount,
    onRowClick,
    onRowSelect,
    initialState: { hiddenColumns: ["_id"] },
  });

  const removeorDeleteOffers = useCallback(
    async ({ shouldDelete = false }) => {
      console.log({ selectedRows });

      if (selectedRows.length === 0) return;

      const selectedOfferIds = selectedRows.map(
        (rowIndex) => tableData[rowIndex]._id
      );

      try {
        const { data } = await removeOffers({
          variables: {
            input: {
              makeOfferIds: selectedOfferIds,
              shouldDelete,
            },
          },
        });

        enqueueSnackbar(
          t("admin.makeOfferTable.bulkActions.archiveSuccess", {
            count: data.removeOffers.makeOfferIds.length,
          }),
          { variant: "success" }
        );
      } catch (error) {
        enqueueSnackbar(
          t("admin.makeOfferTable.bulkActions.error", { variant: "error" })
        );
        return;
      }

      onFetchData({ ...lastFetchVariables, skipCache: true });
    },
    [
      enqueueSnackbar,
      selectedRows,
      lastFetchVariables,
      removeOffers,
      onFetchData,
      tableData,
    ]
  );

  const options = useMemo(
    () => [
      {
        label: "Archivieren",
        confirmTitle: `${selectedRows.length} Angebote archivieren?`,
        confirmMessage:
          "Archivierte Angebote werden sowohl aus der Admin- als auch aus der Kundensicht entfernt.",
        isDisabled: selectedRows.length === 0,
        onClick: () => removeorDeleteOffers({ shouldDelete: false }),
      },
      {
        label: "Löschen",
        confirmTitle: `${selectedRows.length} Angebote löschen?`,
        confirmMessage: "Angebote werden permanent gelöscht.",
        isDisabled: selectedRows.length === 0,
        onClick: () => removeorDeleteOffers({ shouldDelete: true }),
      },
    ],
    [selectedRows, removeorDeleteOffers]
  );

  return (
    <Card className={classes.card}>
      <CardHeader title={t("admin.dashboard.makeAnOfferTitle")} />
      <CardContent>
        <DataTable
          {...dataTableProps}
          actionMenuProps={{ children: "Aktionen", options }}
          placeholder={"Filter orders"}
          isFilterable
          labels={{
            "filterChipValue.offer": t("admin.table.offerStatus.offer"),
            "filterChipValue.offerDeclined": t(
              "admin.table.offerStatus.offerDeclined"
            ),
            "filterChipValue.offerAccepted": t(
              "admin.table.offerStatus.offerAccepted"
            ),
            "filterChipValue.counterOffer": t(
              "admin.table.offerStatus.counterOffer"
            ),
            "filterChipValue.counterOfferAccepted": t(
              "admin.table.offerStatus.counterOfferAccepted"
            ),
            "filterChipValue.counterOfferDeclined": t(
              "admin.table.offerStatus.counterOfferDeclined"
            ),
            allFilters: "Alle Filter",
            allFiltersDrawerTitle: "Alle Filter",
            clearAllFilters: "Alle Filter entfernen",
            clearFilter: "Entfernen",
            globalFilterPlaceholder: "Filter",
            next: "Nächste",
            page: "Seite",
            pageOf: ({ count }) => `von ${count}`,
            pageSizeSelect: ({ count }) => `${count} Zeilen`,
            previous: "Vorherige",
          }}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
}

MakeAnOfferTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(MakeAnOfferTable);
