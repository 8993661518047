import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Translation } from "../../ui/components";

const activeClassName = "nav-item-active";

export default function Subnav({
  classes,
  isMobile,
  route,
  routes,
  history,
  checkDrawerClose,
  isOpen,
  setIsOpen,
}) {
  return (
    <Fragment>
      <a
        className={classNames(classes.link, {
          [activeClassName]: isOpen,
        })}
        onClick={() => {
          // Push the first setting route when opened, but not on mobile
          if (!isOpen && !isMobile) {
            const [firstRoute] = routes;

            if (firstRoute) {
              history.push(firstRoute.path);
            }
          }
          setIsOpen((current) => !current);
        }}
      >
        <ListItem button className={classes.listItem}>
          <ListItemIcon
            className={classNames(classes.icon, {
              [activeClassName]: isOpen,
            })}
          >
            {route.SidebarIconComponent ? <route.SidebarIconComponent /> : ""}
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText}>
            <Translation defaultValue="" i18nKey={route.sidebarI18nLabel} />
          </ListItemText>
        </ListItem>
      </a>

      <Collapse
        className={isOpen ? classes.collapsedMenuOpen : classes.collapsedMenu}
        in={isOpen}
      >
        {routes.map((route) => (
          <NavLink
            activeClassName={activeClassName}
            className={classes.link}
            to={route.path}
            key={route.path}
            onClick={checkDrawerClose}
          >
            <ListItem
              button
              className={classNames(
                classes.listItemNested,
                "nestedListItemSidebar"
              )}
            >
              <ListItemIcon className={classes.iconNested}>
                {route.SidebarIconComponent ? (
                  <route.SidebarIconComponent />
                ) : (
                  ""
                )}
              </ListItemIcon>
              <ListItemText disableTypography className={classes.listItemText}>
                <Translation defaultValue="" i18nKey={route.sidebarI18nLabel} />
              </ListItemText>
            </ListItem>
          </NavLink>
        ))}
      </Collapse>
    </Fragment>
  );
}
