import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/Fab";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import SettingsIcon from "mdi-material-ui/Settings";
import ProductMetadataIcon from "mdi-material-ui/Information";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import ShopLogoWithData from "../ShopLogoWithDataCustom";
import { Translation } from "../../ui/components";

import Subnav from "./Subnav";

const activeClassName = "nav-item-active";

// Route sorting by priority. Items without a priority get pushed the bottom.
const routeSort = (routeA, routeB) =>
  (routeA.priority || Number.MAX_SAFE_INTEGER) -
  (routeB.priority || Number.MAX_SAFE_INTEGER);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.colors.darkBlue600,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.colors.darkBlue500,
      },
    },
  },
  icon: {
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    marginRight: theme.spacing(2),
    // color: theme.palette.colors.coolGrey300
    color: "#f1f1f1",
  },
  iconNested: {
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    marginRight: theme.spacing(1),
    // color: theme.palette.colors.coolGrey300
    color: "#f1f1f1",
  },
  iconActive: {
    // color: theme.palette.text.active
    color: theme.palette.secondary.main,
  },
  shopLogo: {
    flex: 1,
    //marginRight: theme.spacing(2)
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  list: {
    margin: theme.spacing(1, 0),
  },
  listItem: {
    width: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      // backgroundColor: theme.palette.colors.darkBlue600,
      backgroundColor: "#ffffff6b",
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
      borderRadius: 3,
      transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
    },
  },
  listItemText: {
    paddingLeft: 0,
    fontSize: theme.typography.fontSize,
    lineHeight: 1.5,
    letterSpacing: 0.5,
    // color: theme.palette.colors.black15
    color: "#f1f1f1",
    // fontWeight: 300
  },
  listItemNested: {
    width: "auto",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(3),
    "&:hover": {
      // backgroundColor: theme.palette.colors.darkBlue600,
      backgroundColor: "#ffffff6b",
      padding: theme.spacing(0, 1),
      margin: theme.spacing(0, 1),
      // paddingLeft: theme.spacing(2),
      marginLeft: theme.spacing(2),
      borderRadius: 3,
      transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
    },
  },
  link: {
    [`&.${activeClassName} span`]: {
      // color: theme.palette.text.secondaryActive,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    [`&.${activeClassName} .MuiListItem-button`]: {
      background: "#FFFFFF",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.12)",
      borderRadius: 3,
      margin: theme.spacing(0, 1),
      padding: theme.spacing(1),
    },
    [`&.${activeClassName} .nestedListItemSidebar`]: {
      marginLeft: theme.spacing(3),
      paddingLeft: 0,
      padding: theme.spacing(0, 1, 0, 2),
    },
    [`&.${activeClassName} $icon`]: {
      color: theme.palette.secondary.main,
    },
    [`&.${activeClassName} $iconNested`]: {
      color: theme.palette.secondary.main,
    },
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
  },
  drawerPaper: {
    overflowY: "scroll !important",
    background: theme.palette.secondary.main,
    color: "#FFFFFF",
    boxShadow: "2px 0px 2px 0 rgba(0, 0, 0, 0.05)",
  },
  logoDivider: {
    width: "auto",
    margin: theme.spacing(0, 2),
    backgroundColor: "#ffffff80",
  },
  collapsedMenuOpen: {
    margin: theme.spacing(1, 0),
  },
  collapsedMenu: {},
}));

/**
 * Main sidebar navigation
 * @param {Object} props Component props
 * @returns {React.Component} Sidebar component
 */
function Sidebar({
  history,
  isMobile,
  isSidebarOpen,
  onDrawerClose,
  routes: unfilteredRoutes,
  viewer,
}) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProductMetadataOpen, setIsProductMetadataOpen] = useState(false);

  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isAccountsOpen, setIsAccountsOpen] = useState(false);
  const classes = useStyles();

  const specialRouteAccess = (viewer && viewer.specialRouteAccess) || [];
  const routes = unfilteredRoutes.filter(
    (route) =>
      !route.specialAccessId ||
      specialRouteAccess.includes(route.specialAccessId)
  );

  // We reduce routes to show navItem in current position
  const primaryRoutes = routes
    .reduce(
      ({ list, isProductInserted, isAccountsInserted }, currentRoute) => {
        const {
          isNavigationLink,
          isSetting,
          isProductMetadata,
          isProduct,
          isAccounts,
        } = currentRoute;

        if (isProduct) {
          if (!isProductInserted) {
            return { list: [...list, currentRoute], isProductInserted: true };
          }

          return { list, isProductInserted, isAccountsInserted };
        }

        if (isAccounts) {
          if (!isAccountsInserted) {
            return { list: [...list, currentRoute], isAccountsInserted: true };
          }

          return { list, isProductInserted, isAccountsInserted };
        }

        if (isNavigationLink && !isSetting && !isProductMetadata) {
          list.push(currentRoute);
        }

        return { list, isProductInserted, isAccountsInserted };
      },
      { list: [], isProductInserted: false, isAccountsInserted: false }
    )
    .list.sort(routeSort);

  const settingRoutes = routes
    .filter(({ isNavigationLink, isSetting }) => isNavigationLink && isSetting)
    .sort(routeSort);
  const productMetadataRoutes = routes
    .filter(
      ({ isNavigationLink, isProductMetadata }) =>
        isNavigationLink && isProductMetadata
    )
    .sort(routeSort);

  const productRoutes = routes
    .filter(({ isNavigationLink, isProduct }) => isNavigationLink && isProduct)
    .sort(routeSort);

  const accountRoutes = routes
    .filter(
      ({ isNavigationLink, isAccounts }) => isNavigationLink && isAccounts
    )
    .sort(routeSort);

  let drawerProps = {
    classes: {
      paper: classes.drawerPaper,
    },
    variant: "persistent",
    anchor: "left",
    open: isSidebarOpen,
    onClose: onDrawerClose,
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
  };

  if (isMobile) {
    drawerProps = {
      variant: "temporary",
      anchor: "left",
      open: isSidebarOpen,
      onClose: onDrawerClose,
      ModalProps: {
        keepMounted: true, // Better open performance on mobile.
      },
    };
  }

  const checkDrawerClose = () => {
    if (isMobile) {
      onDrawerClose();
    }
  };

  return (
    <SwipeableDrawer {...drawerProps}>
      <AppBar
        color="secondary"
        elevation={0}
        className={classes.appBar}
        //position="sticky"
      >
        <Toolbar className={classes.toolbar}>
          <ShopLogoWithData
            className={classes.shopLogo}
            /*shouldShowShopName*/ size={190}
          />

          {/* <Hidden mdUp> */}
          <IconButton
            classes={{ root: classes.closeButton }}
            onClick={onDrawerClose}
            size="small"
          >
            <ChevronLeft />
          </IconButton>
          {/* </Hidden> */}
        </Toolbar>
        {!isMobile && <Divider className={classes.logoDivider} />}
      </AppBar>
      <List disablePadding className={classes.list}>
        {primaryRoutes.map((route) => {
          if (route.isProduct) {
            return (
              <Subnav
                {...{ classes, history, isMobile, checkDrawerClose }}
                route={route}
                routes={productRoutes}
                isOpen={isProductOpen}
                setIsOpen={setIsProductOpen}
              />
            );
          }

          if (route.isAccounts) {
            return (
              <Subnav
                {...{ classes, history, isMobile, checkDrawerClose }}
                route={route}
                routes={accountRoutes}
                isOpen={isAccountsOpen}
                setIsOpen={setIsAccountsOpen}
              />
            );
          }

          return (
            <NavLink
              activeClassName={
                !isSettingsOpen && !isProductMetadataOpen
                  ? activeClassName
                  : null
              }
              className={classes.link}
              to={route.path}
              key={route.path}
              onClick={() => {
                setIsSettingsOpen(false);
                setIsProductMetadataOpen(false);
                setIsProductOpen(false);

                if (isMobile) {
                  onDrawerClose();
                }
              }}
            >
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  {route.SidebarIconComponent ? (
                    <route.SidebarIconComponent />
                  ) : (
                    ""
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                >
                  <Translation
                    defaultValue=""
                    i18nKey={route.sidebarI18nLabel}
                  />
                </ListItemText>
              </ListItem>
            </NavLink>
          );
        })}

        <a
          className={classNames(classes.link, {
            [activeClassName]: isProductMetadataOpen,
          })}
          onClick={() => {
            // Push the first setting route when opened, but not on mobile
            if (!isProductMetadataOpen && !isMobile) {
              const [firstRoute] = productMetadataRoutes;

              if (firstRoute) {
                history.push(firstRoute.path);
              }
            }
            setIsProductMetadataOpen(!isProductMetadataOpen);
          }}
        >
          <ListItem button className={classes.listItem}>
            <ListItemIcon
              className={classNames(classes.icon, {
                [activeClassName]: isProductMetadataOpen,
              })}
            >
              <ProductMetadataIcon />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.listItemText}>
              {/* <Translation i18nKey={"app.settings"} /> */}
              <span>Produktmetadaten</span>
            </ListItemText>
          </ListItem>
        </a>

        <Collapse
          className={
            isProductMetadataOpen
              ? classes.collapsedMenuOpen
              : classes.collapsedMenu
          }
          in={isProductMetadataOpen}
        >
          {productMetadataRoutes.map((route) => (
            <NavLink
              activeClassName={activeClassName}
              className={classes.link}
              to={route.path}
              key={route.path}
              onClick={checkDrawerClose}
            >
              <ListItem
                button
                className={classNames(
                  classes.listItemNested,
                  "nestedListItemSidebar"
                )}
              >
                <ListItemIcon className={classes.iconNested}>
                  {route.SidebarIconComponent ? (
                    <route.SidebarIconComponent />
                  ) : (
                    ""
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                >
                  <Translation
                    defaultValue=""
                    i18nKey={route.sidebarI18nLabel}
                  />
                </ListItemText>
              </ListItem>
            </NavLink>
          ))}
        </Collapse>

        <a
          className={classNames(classes.link, {
            [activeClassName]: isSettingsOpen,
          })}
          onClick={() => {
            // Push the first setting route when opened, but not on mobile
            if (!isSettingsOpen && !isMobile) {
              const [firstRoute] = settingRoutes;

              if (firstRoute) {
                history.push(firstRoute.path);
              }
            }
            setIsSettingsOpen(!isSettingsOpen);
          }}
        >
          <ListItem button className={classes.listItem}>
            <ListItemIcon
              className={classNames(classes.icon, {
                [classes.iconActive]: isSettingsOpen,
              })}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.listItemText}>
              <Translation i18nKey={"app.settings"} />
            </ListItemText>
          </ListItem>
        </a>

        <Collapse
          className={
            isSettingsOpen ? classes.collapsedMenuOpen : classes.collapsedMenu
          }
          in={isSettingsOpen}
        >
          {settingRoutes.map((route) => (
            <NavLink
              activeClassName={activeClassName}
              className={classes.link}
              to={route.path}
              key={route.path}
              onClick={checkDrawerClose}
            >
              <ListItem
                button
                className={classNames(
                  classes.listItemNested,
                  "nestedListItemSidebar"
                )}
              >
                <ListItemIcon className={classes.iconNested}>
                  {route.SidebarIconComponent ? (
                    <route.SidebarIconComponent />
                  ) : (
                    ""
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                >
                  <Translation
                    defaultValue=""
                    i18nKey={route.sidebarI18nLabel}
                  />
                </ListItemText>
              </ListItem>
            </NavLink>
          ))}
        </Collapse>
      </List>
    </SwipeableDrawer>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isMobile: PropTypes.bool,
  isSidebarOpen: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  routes: PropTypes.array,
};

Sidebar.defaultProps = {
  setIsSidebarOpen() {},
};

export default withRouter(Sidebar);
