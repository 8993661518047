import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import makeDataTableColumnFilter from "../../shared/useDataTable-custom/makeDataTableColumnFilter";
import InlineAlert from "@reactioncommerce/components/InlineAlert/v1";
import primaryShopIdQuery from "../../../../graphql/queries/getPrimaryShopId";
import {
  Grid,
  Box,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import ChevronRightIcon from "mdi-material-ui/ChevronRight";
import ChevronUpIcon from "mdi-material-ui/ChevronUp";
import CloseIcon from "mdi-material-ui/Close";
import accountsQuery from "../graphql/queries/accounts";
import pendingAccountsQuery from "../graphql/queries/pendingAccounts";
import DateCell from "./TableCells/DateCell";
import AvatarCell from "../../shared/components/TableCells/AvatarCell";
import OfferIdCell from "./TableCells/OfferIdCell";
import VisibilityCell from "./TableCells/VisibilityCell";
import TrueFalseCell from "./TableCells/TrueFalseCell";
import FamilyFriendsCell from "./TableCells/FamilyFriendsCell";
import ExpandedComponent from "./ExpandedRow";

import queryString from "query-string";
import AccountEditMenu from "./AccountEditMenu";

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

const ACTIVATE_USERS = gql`
  mutation activateUserAccounts($input: ActivateUserAccountsInput!) {
    activateUserAccounts(input: $input) {
      accounts {
        _id
        isActivated
        confirmedEmail
      }
    }
  }
`;

const MANUAL_CONFIRM_EMAIL = gql`
  mutation forceConfirmUserEmails($input: ForceConfirmUserEmailInput!) {
    forceConfirmUserEmails(input: $input) {
      accounts {
        _id
        confirmedEmail
      }
    }
  }
`;

const TOGGLE_ADVANCE_PAYMENT_USERS = gql`
  mutation toggleInvoicePaymentAllowed(
    $input: ToggleInvoicePaymentAllowedInput!
  ) {
    toggleInvoicePaymentAllowed(input: $input) {
      accounts {
        _id
        invoicePaymentAllowed
      }
    }
  }
`;

const DELETE_USERS = gql`
  mutation deleteAccounts($input: DeleteAccountsInput!) {
    deleteAccounts(input: $input) {
      result
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  button: {
    color: theme.palette.colors.coolGrey500,
    fontWeight: 600,
    borderRadius: 0,
  },
  buttonAsc: {
    borderTop: "2px solid",
  },
  buttonDesc: {
    borderBottom: "2px solid",
  },
}));

const getQueryVariables = ({
  shopIds,
  state: { sortBy, filters, pageIndex, pageSize },
  globalFilter,
}) => {
  let filterObject = {};

  if (filters && filters.length) {
    filters.forEach((filter) => {
      filterObject[filter.id] = filter.value;
    });
  }

  return {
    shopIds,
    first: pageSize,
    offset: pageIndex * pageSize,
    filters: {
      searchField: globalFilter,
      ...filterObject,
    },
    sortBy,
  };
};

/**
 * @name AccountTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function AccountTable({ history, isPending = false }) {
  const apolloClient = useApolloClient();
  const classes = useStyles();

  const [
    activateUserAccounts,
    { error: activateUserAccountsError },
  ] = useMutation(ACTIVATE_USERS);

  const [
    forceConfirmUserEmails,
    { error: forceConfirmUserEmailsError },
  ] = useMutation(MANUAL_CONFIRM_EMAIL);

  const [
    toggleInvoicePaymentAllowed,
    { error: toggleInvoicePaymentAllowedError },
  ] = useMutation(TOGGLE_ADVANCE_PAYMENT_USERS);

  const [deleteAccounts, { error: deleteAccountsError }] = useMutation(
    DELETE_USERS
  );

  const [totalAccounts, setTotalAccounts] = useState(0);

  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const [lastFetchVariables, setLastFetchVariables] = useState({});

  const userTableState = JSON.parse(localStorage.getItem("accountTable"));

  const querySearchTerm = useMemo(
    () => queryString.parse(location.search).searchTerm,
    [location]
  );

  const initialState = {
    hiddenColumns: [
      "_id",
      "isVisible",
      "invoiceAddress.city",
      "invoiceAddress.postal",
      "invoiceAddress.region",
      "invoiceAddress.country",
      "invoiceAddress.address1",
    ],
    ...userTableState,
    globalFilter: querySearchTerm,
  };

  const { t } = useTranslation("ns1");

  // Create and memoize the column data
  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander",
        cellProps: {
          isClickDisabled: true,
          padding: "none",
        },
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <IconButton className={classes.expandButton}>
                <ChevronUpIcon />
              </IconButton>
            ) : (
              <IconButton className={classes.expandButton}>
                <ChevronRightIcon />
              </IconButton>
            )}
          </span>
        ),
        Expanded: (props) => <ExpandedComponent {...props} />,
      },
      {
        id: "selection",
        disableSorting: true,
        cellProps: {
          // Disables the cell click if the row is clickable
          // This is important if you have a callback for onRowClick, as the checkbox cell
          // will also trigger the row click.
          // Alternatively you can control the onClick with the following option
          // onClick: (event) => event.stopPropagation(),
          isClickDisabled: true,

          // All other props will be applied to the table cell.
          padding: "none",
        },
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Checkbox {...getToggleAllRowsSelectedProps()} />
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Cell: ({ row }) => (
          <Checkbox
            {...row.getToggleRowSelectedProps()}
            title={`Toggle row selection for ${row.values.fullName}`}
          />
        ),
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ({ row }) => <AccountEditMenu row={row} history={history} />,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <AvatarCell account={row.original} showLink={false} />
        ),
      },
      {
        Header: "E-Mail",
        accessor: "primaryEmailAddress",
      },
      {
        Header: "Firma",
        accessor: "company",
      },
      {
        Header: "Straße & Hausnr.",
        accessor: "invoiceAddress.address1",
      },
      {
        Header: "Stadt",
        accessor: "invoiceAddress.city",
      },
      {
        Header: "PLZ",
        accessor: "invoiceAddress.postal",
      },
      {
        Header: "Region",
        accessor: "invoiceAddress.region",
      },
      {
        Header: "Land",
        accessor: "invoiceAddress.country",
      },
      {
        // Header: "Offer ID",
        Header: "Account ID",
        accessor: "_id",
        Cell: ({ row }) => <OfferIdCell row={row} />,
      },
      {
        Header: "VAT ID",
        accessor: "vatId",
        Cell: ({ cell }) => <Box>{cell.value || null}</Box>,
      },
      {
        Header: "Datev ID",
        accessor: "datevId",
        Cell: ({ cell }) => <Box>{cell.value || null}</Box>,
      },
      {
        Header: "Datum",
        accessor: "createdAt",
        Cell: ({ row }) => <DateCell row={row} />,
      },
      {
        Header: "Verifiziert",
        accessor: "confirmedEmail",
        Cell: ({ cell }) => <TrueFalseCell value={cell.value} />,
      },
      ...(isPending
        ? []
        : [
            {
              Header: "Freigeschaltet",
              accessor: "isActivated",
              Filter: makeDataTableColumnFilter({
                options: [
                  {
                    label: t("admin.accountTable.isActivated"),
                    value: "isActivated",
                  },
                  {
                    label: t("admin.accountTable.isNotActivated"),
                    value: "isNotActivated",
                  },
                ],
              }),
              Cell: ({ cell }) => <TrueFalseCell value={cell.value} />,
            },
          ]),
      {
        Header: "Kauf auf Rechnung",
        accessor: "invoicePaymentAllowed",
        ...(isPending
          ? {}
          : {
              Filter: makeDataTableColumnFilter({
                options: [
                  {
                    label: t("admin.accountTable.invoicePaymentAllowed"),
                    value: "invoicePaymentAllowed",
                  },
                  {
                    label: t("admin.accountTable.invoicePaymentNotAllowed"),
                    value: "invoicePaymentNotAllowed",
                  },
                ],
              }),
            }),
        Cell: ({ cell }) => <TrueFalseCell value={cell.value} />,
      },
      {
        Header: "Nutzertyp",
        accessor: "isFamilyAndFriends",
        Filter: makeDataTableColumnFilter({
          options: [
            {
              label: t("admin.accountTable.familyAndFriends"),
              value: "isFamilyAndFriends",
            },
            {
              label: t("admin.accountTable.businessClient"),
              value: "isNotFamilyAndFriends",
            },
          ],
        }),
        Cell: ({ cell }) => <FamilyFriendsCell value={cell.value} />,
      },
      {
        Header: "Sichtbarkeit",
        accessor: "isVisible",
        Cell: ({ row }) => <VisibilityCell row={row} />,
      },
    ],
    [classes.expandButton, isPending]
  );

  const onFetchData = useCallback(
    async ({
      globalFilter,
      filters,
      pageIndex,
      pageSize,
      sortBy,
      skipCache = false,
    }) => {
      setIsLoading(true);
      setLastFetchVariables({
        globalFilter,
        filters,
        pageIndex,
        pageSize,
        sortBy,
      });

      const { data: shopData } = await apolloClient.query({
        query: primaryShopIdQuery,
      });

      // TODO: Add loading and error handling
      const { data } = await apolloClient.query({
        query: isPending ? pendingAccountsQuery : accountsQuery,
        variables: getQueryVariables({
          shopIds: [shopData.primaryShopId],
          state: { globalFilter, filters, pageIndex, pageSize, sortBy },
          globalFilter,
        }),
        fetchPolicy: skipCache ? "network-only" : "cache-first",
      });

      const dataKey = isPending ? "pendingAccounts" : "accountsCustom";

      const totalCount = data?.[dataKey]?.totalCount;
      const nodes = data?.[dataKey]?.nodes;

      setTableData(nodes);
      setTotalAccounts(totalCount);
      setPageCount(Math.ceil(totalCount / pageSize));
      setIsLoading(false);
    },
    [
      apolloClient,
      setTableData,
      setPageCount,
      setIsLoading,
      setTotalAccounts,
      isPending,
    ]
  );

  // Row click callback
  /*
  const onRowClick = useCallback(async ({ row }) => {
    const { id: decodedId } = decodeOpaqueId(row.values._id);
    if (row.values.type === "bundle") {
      history.push(`/accounts-cto/${decodedId}`);
    } else {
      history.push(`/accounts/${decodedId}`);
    }
  }, [history]);
  */

  // const onRowClick = useCallback(async ({ row }) => {
  //     console.log(row.values);
  //     history.push(`/account-manager/${row.values._id}`);
  // }, [history]);

  const onRowSelect = useCallback(
    async ({ selectedRows: newSelectedRows }) => {
      setSelectedRows(newSelectedRows || []);
    },
    [setSelectedRows, tableData]
  );

  const dataTableProps = useDataTable({
    data: tableData,
    columns,
    onFetchData,
    pageCount,
    // onRowClick,
    onRowSelect,
    initialState,
  });

  const {
    state: {
      hiddenColumns,
      sortBy,
      filters,
      pageIndex,
      pageSize,
      globalFilter,
    },
    setGlobalFilter,
  } = dataTableProps;

  /*
  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (queryParams.searchTerm) {
      setTimeout(() => {
        setGlobalFilter(queryParams.searchTerm);
      }, 500);
    }
  }, [setGlobalFilter, location]);
  */

  useEffect(() => {
    localStorage.setItem(
      "accountTable",
      JSON.stringify({ hiddenColumns, pageSize })
    );
  }, [hiddenColumns, pageSize]);

  const options = useMemo(
    () => [
      {
        label: "Nutzer löschen",
        isDisabled: selectedRows.length === 0,
        confirmTitle: `${selectedRows.length} Nutzer löschen?`,
        confirmMessage:
          "Nutzer sowie zugehörige Daten werden permanent gelöscht.",
        onClick: async () => {
          const accountIds = (selectedRows || []).map(
            (rowIndex) => tableData[rowIndex]._id
          );

          await deleteAccounts({
            variables: {
              input: {
                accountIds,
              },
            },
          });

          onFetchData({ ...lastFetchVariables, skipCache: true });
        },
      },
      ...(isPending
        ? []
        : [
            {
              label: "Nutzer freischalten",
              isDisabled: selectedRows.length === 0,
              onClick: async () => {
                const accountIds = (selectedRows || []).map(
                  (rowIndex) => tableData[rowIndex]._id
                );

                await activateUserAccounts({
                  variables: {
                    input: {
                      accountIds,
                    },
                  },
                });

                onFetchData(lastFetchVariables);
              },
            },
          ]),
      {
        label: "E-Mail-Adresse manuell bestätigen",
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const accountIds = (selectedRows || []).map(
            (rowIndex) => tableData[rowIndex]._id
          );

          await forceConfirmUserEmails({
            variables: {
              input: {
                accountIds,
              },
            },
          });

          onFetchData(lastFetchVariables);
        },
      },
      {
        label: "E-Mail-Adresse manuell bestätigen",
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const accountIds = (selectedRows || []).map(
            (rowIndex) => tableData[rowIndex]._id
          );

          await forceConfirmUserEmails({
            variables: {
              input: {
                accountIds,
              },
            },
          });

          onFetchData(lastFetchVariables);
        },
      },
      {
        label: "Kauf auf Rechnung aktivieren",
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const accountIds = (selectedRows || []).map(
            (rowIndex) => tableData[rowIndex]._id
          );

          await toggleInvoicePaymentAllowed({
            variables: {
              input: {
                accountIds,
                isEnabled: true,
              },
            },
          });

          onFetchData(lastFetchVariables);
        },
      },
      {
        label: "Kauf auf Rechnung deaktivieren",
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          const accountIds = (selectedRows || []).map(
            (rowIndex) => tableData[rowIndex]._id
          );

          await toggleInvoicePaymentAllowed({
            variables: {
              input: {
                accountIds,
                isEnabled: false,
              },
            },
          });

          onFetchData(lastFetchVariables);
        },
      },
    ],
    [
      activateUserAccounts,
      forceConfirmUserEmails,
      toggleInvoicePaymentAllowed,
      deleteAccounts,
      tableData,
      selectedRows,
      onFetchData,
      t,
      isPending,
    ]
  );

  return (
    <Grid container spacing={3}>
      {activateUserAccountsError && (
        <Grid item sm={12}>
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={activateUserAccountsError.message}
          />
        </Grid>
      )}
      {forceConfirmUserEmailsError && (
        <Grid item sm={12}>
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={forceConfirmUserEmailsError.message}
          />
        </Grid>
      )}
      {toggleInvoicePaymentAllowedError && (
        <Grid item sm={12}>
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={toggleInvoicePaymentAllowedError.message}
          />
        </Grid>
      )}
      {deleteAccountsError && (
        <Grid item sm={12}>
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={deleteAccountsError.message}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        <Card className={classes.card}>
          <CardHeader
            title={t("admin.dashboard.accountAdminTitle")}
            subheader={
              selectedRows.length > 0
                ? t("admin.accountTable.bulkActions.selectedCount", {
                    count: selectedRows.length,
                  })
                : t("admin.accountTable.bulkActions.totalCount", {
                    count: totalAccounts,
                  })
            }
          />
          <CardContent>
            <DataTable
              {...dataTableProps}
              actionMenuProps={{ children: "Aktionen", options }}
              placeholder={"Filter orders"}
              isFilterable
              labels={{
                "filterChipValue.isFamilyAndFriends": t(
                  "admin.accountTable.familyAndFriends"
                ),
                "filterChipValue.isNotFamilyAndFriends": t(
                  "admin.accountTable.businessClient"
                ),
                "filterChipValue.isActivated": t(
                  "admin.accountTable.isActivated"
                ),
                "filterChipValue.isNotActivated": t(
                  "admin.accountTable.isNotActivated"
                ),
                allFilters: "Alle Filter",
                allFiltersDrawerTitle: "Alle Filter",
                clearAllFilters: "Alle Filter entfernen",
                clearFilter: "Entfernen",
                globalFilterPlaceholder: "Filter",
                next: "Nächste",
                page: "Seite",
                pageOf: ({ count }) => `von ${count}`,
                pageSizeSelect: ({ count }) => `${count} Zeilen`,
                previous: "Vorherige",
              }}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

AccountTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(AccountTable);
